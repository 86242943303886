<template>
  <div ref="dialog" class="quiz-boss">
    <!-- header -->
    <div class="quiz-header">
      {{ currentQuestion }} вопрос из
      {{ totalQuestions }}
    </div>
    <div class="quiz-content" ref="content">
      <div
        class="flex flex-row flex-wrap w-full flex-shrink-0"
        :style="{
          position: 'relative',
          paddingBottom: '0px',
        }"
      >
        <!-- question.image -->
        <div
          v-if="question.image && $store.state.ui.width >= 1200"
          class=""
          :style="{
            position: 'absolute',
            zIndex: 400,
            top: '0px',
            right: '-20px',
            overflow: 'visible',
            width: '0px',
          }"
        ></div>
        <!-- question.title -->
        <p class="question-text">
          <vue-markdown
            :source="question.question"
            @rendered="update"
          ></vue-markdown>
        </p>
        <img
          v-if="question.image"
          draggable="false"
          class="boss-image"
          :src="question.image"
          :style="{
            zIndex: 10,
            height: $store.state.ui.width > 768 ? '300px' : '200px',
            maxWidth: '100%',
            pointerEvents: 'none',
            userSelect: 'none',
            objectFit: 'contain',
            marginTop: '20px',
            marginBottom: '20px',
          }"
        />
      </div>
      <!-- image mobile -->

      <!-- options -->
      <!-- :disabled="answer.percent" -->
      <div class="answers-block-title">Выбери верный вариант ответа</div>
      <div class="w-full">
        <button
          v-for="answer in question.answers"
          :key="answer.id"
          style="max-width: 500px; width: 100%"
          class="ans-btn quiz__answer"
          :class="{
            answered: answerSelectedId === answer.id,
            wrong: answerSelectedId === answer.id && isRight === false,
          }"
          :disabled="description"
          :style="{
            height: 'auto !important',
          }"
          @click="answerSelectedId = answer.id"
        >
          {{ answer.text }}
        </button>
      </div>
      <div v-if="description" class="description">
        <p>
          {{description}}
        </p>
      </div>
      <!-- answer btn -->
      <div
        class="flex flex-row flex-wrap w-full"
        :style="{
          paddingTop: '20px',
          minHeight: '60px',
        }"
      >
        <button
          v-if="true"
          class="geecko-button"
          :class="{'geecko-button--loading': isAnswerLoading || isNextQuestionLoading}"
          :disabled="isAnswerLoading || isNextQuestionLoading"
          :style="{
            background: !answerSent ? '#237a3b' : '#237a3b',
          }"
          @click="(answerSent = true), answer()"
        >
          {{ description ? 'Далее' : 'Ответить' }}
        </button>
      </div>
      
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown';
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css'; // theme
import 'prismjs/components/prism-sql.min'; // language

export default {
  name: 'QuizBoss',
  components: {
    VueMarkdown,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    description: {
      type: String,
    },
    isRight: {
      type: Boolean,
    },
    isAnswerLoading: {
      type: Boolean,
    },
    isNextQuestionLoading: {
      type: Boolean,
    }
  },
  data() {
    return {
      result: null,
      answerSelectedId: null,
      answerSent: false,
    };
  },
  computed: {
    isImageAnswers() {
      return this.question.answers.some((item) => !!item.image);
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    currentLevel() {
      return this.gameInfo?.current_level
    },
    currentLevelResult() {
      return this.gameInfo?.result.level[this.currentLevel];
    },
    totalQuestions() {
      return this.currentLevelResult?.total
    },
    currentQuestion() {
      let allquestions = this.currentLevelResult?.correct + this.currentLevelResult?.incorrect
      if (allquestions < 5) return allquestions + 1
      else return allquestions
    }
  },
  watch: {
    question: {
      deep: true,
      handler(to) {
        this.$log('question TO', to);
        if (to) {
          this.answerSent = false;
          this.answerSelectedId = null;
        }
        this.scrollToTop()
        console.log('question change');
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      const dialog = this.$refs.dialog;
      this.$tween.set(dialog, { opacity: '0' });
      this.$tween.to(dialog, 1, { opacity: '1' });
    });
  },
  methods: {
    scrollToTop() {
      this.$nextTick(() => {
        let block = this.$refs["content"];
        if (block) {
          block.scrollTop = 0;
        }
      });
    },
    update: function () {
      this.$nextTick(() => {
        Prism.highlightAll();
      });
    },
    async answer() {
      if (!this.description) {
        if (this.answerSelectedId) this.$emit('answer', this.answerSelectedId);
      } else {
        this.$emit('next')
      }
    },
    async nextQuestion() {

    }
  },
};
</script>

<style lang="scss">
.description {
  color: white;
  opacity: 0.7;
  margin-top: 30px;
}
.quiz-boss {
  @apply relative overflow-hidden w-full;
  max-width: 700px;
  height: 75vh;
  background: #14161a;
  border-radius: 10px;
  .quiz-header {
    @apply px-8 font-blenderpro-bold uppercase;
    color: #e0e0e0;
    background: #111316;
    --height: 48px;
    height: var(--height);
    line-height: var(--height);
    font-size: 18px;
    color: #ffffff;
  }
  .quiz-content {
    @apply absolute top-12 left-0 flex flex-col overflow-auto px-8 py-6;
    height: calc(100% - 48px);
  }
  .question-text {
    @apply w-full;
    font-weight: bold;
    color: #ffffff;
    & p {
      color: #ffffff;
    }
    & pre {
      background-color: #000000;
      font-size: 12px;
    }
  }
  .quiz__answer {
    display: block;
    // position: relative;
    margin: 4px 0;
    max-width: 700px !important;
    width: 100% !important;
    position: relative;
    padding-right: 64px;
    padding-bottom: 10px;
    padding-top: 10px;
    &.answered {
      // --border-color: rgb(255,240,64);
      // color: white;
      // border: 1px solid rgb(255,240,64);
      border: 2px solid rgba(43, 154, 74, 1);
      color: rgba(43, 154, 74, 1);
      opacity: 2;
    }
    &.wrong {
      border: 2px solid rgb(154, 43, 43);
      color: rgb(154, 43, 43);
      opacity: 2;
    }
  }
  .boss-image {
    @apply mx-auto;
  }
  .ans-btn {
    background: rgba(0, 0, 0, 0.5);
    padding-left: 20px;
    text-align: left;
    border: 1px solid #1d3925;
    color: rgb(131, 131, 131);
    opacity: 0.6;
    font-weight: 700;
    border-radius: 5px;
  }
  .image-answer {
    @apply mx-3 p-2 h-full flex flex-col justify-start;
    background: linear-gradient(0deg, #111316, #111316);
    .answer-title {
      @apply font-blenderpro-regular mb-3;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }
    &.answered {
      outline: 2px solid rgba(43, 154, 74, 1);
      & .answer-title {
        color: #2b9a4a;
      }
    }
    &:hover {
      box-shadow: 0px 0px 26px 5px rgb(109 237 252 / 25%);
    }
  }
  .answers-block-title {
    @apply font-blenderpro-bold my-6;
    font-size: 16px;
    line-height: 22px;
    color: #e0e0e0;
  }
}

@media screen and (max-width: 768px) {
  .quiz-boss {
    left: 0;
    max-width: 100vw !important;
    width: 100% !important;
    height: calc(65vh - 40px);
  }
  .quiz-content {
    // padding: none !important;
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    // border: 1px solid red;
  }
}
</style>
