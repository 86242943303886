<template>
  <div class="position-npc">
    <div ref="container" class="npc-container">
      <div ref="npc" class="npc" :style="{width: width + 'px'}" />
      <div ref="bubble" class="bubble">
        <p>{{ msg }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { SteppedEase } from 'gsap';

export default {
  name: 'Npc',
  data() {
    return {
      msg: '',
      width: 200,
      timeline: null,
      npc: null,
      animImg: {
        approve: 46, emotion: 34, positive: 53, talk: 24, wrong: 34
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      const container = this.$refs.container;
      const bubble = this.$refs.bubble;
      this.npc = this.$refs.npc
      this.$tween.set(container, { x: 200 });
      this.$tween.set(bubble, { transform: 'scale(0)' });
    })
  },
  methods: {
    async animAppear(s, time, type) {
      this.animation(type)
      this.msg = s;
      const container = this.$refs.container;
      const bubble = this.$refs.bubble;
      this.$tween.to(container, 1, { x: 90 });
      await this.$wait(500);
      this.$tween.to(bubble, 0.5, { transform: 'scale(1)' });
      await this.$wait(time);
      this.$tween.to(bubble, 0.5, { transform: 'scale(0)' });
      await this.$wait(500);
      this.timeline.kill()
      this.$tween.to(container, 1, { x: 200 });
    },
    async animation(type) {
      this.timeline = new this.$timeline({ repeat: -1 });
      this.timeline.set(this.npc, {
        backgroundImage: `url(/images/game/stareyshina/${type}.png)`,
        backgroundSize: `${this.width * this.animImg[type]}px`,
        backgroundPosition: 'left bottom',
      });
      this.$log(`url(/images/game/stareyshina/${type}.png)`, this.animImg[type])
      this.timeline.to(this.npc, 1, { repeat: 1, backgroundPositionX: `-${this.width * this.animImg[type]}px`, ease: SteppedEase.config(this.animImg[type]) })
    }
  },
};
</script>
<style lang="scss" scoped>
.position-npc {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.npc-container {
  width: 200px;
  height: 220px;
  position: absolute;
  z-index: 1000;
}
.npc {
  height: 220px;
  // background-image: url('/images/npc.png');
  // background-repeat: no-repeat;
  z-index: 1000;
  // border: 1px solid red;
  transform: rotate(-30deg);
}
.bubble {
  position: relative;
  max-width: 30em;
  background-color: #fff;
  padding: 10px;
  font-size: 1.25em;
  border-radius: 1rem;
  z-index: 100;
  // border: 1px solid red;
  bottom: 130%;
  right: 70%;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 100%;
    right: 0.6em;
    border: .75rem solid transparent;
    border-top: none;
    transform: rotate(180deg);
    border-bottom-color: #fff;
  }
}
</style>
