<template>
  <div class="info-container">
    <div class="info-container-block">
      <h2> Как будешь играть? </h2>
      <div class="buttons-container">
        <button
          class="geecko-button justify-center items-center"
          type="submit"
          @click="handleSubmit('music-on')"
          :style="{
            background: '#FFEF40',
            display: 'flex',
            flexDirection: 'flex-row',
            maxWidth: '180px',
            width: '300px',
            border: '1px solid red;',
            padding: 0
          }"
        >
          <div class="music music-on" />
          C музыкой
        </button>
        <button
          class="geecko-button--outline justify-center items-center"
          type="submit"
          @click="handleSubmit('music-off')"
          :style="{
            display: 'flex',
            flexDirection: 'flex-row',
            maxWidth: '180px',
            width: '300px',
            border: '1px solid red;',
            padding: 0
          }"
        >
          <div class="music music-off" />
          Без музыки
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InfoMusic',
  methods: {
    async handleSubmit(music) {
      if (music === "music-on") {
        this.$store.commit("ui/SET_STATE_PROP", ["soundOn", true]);
      }
      this.$emit("finished")
    },
  }
}
</script>
<style lang="scss" scoped>
.music {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  // border: 1px solid red;
  &-on {
    background: url('/images/icons/volume_on_16px.svg');
    @apply bg-contain bg-no-repeat bg-center;
  }
  &-off {
    background: url('/images/icons/volume_off_16px.svg');
    @apply bg-contain bg-no-repeat bg-center;
  }
}
.info-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  
  &-block {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    padding: 30px;
    width: 100%;
    background: #191B20;
    border-radius: 8px;
    @apply flex flex-col items-center;
    // background: #2e2c3f;
    p, h2 {
      color: #fff;
      margin: 10px;
    }
    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      button {
        max-width: 150px;
        width: 100%;
        margin-top: 20px;
        margin: 10px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
.info-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  &-block {
    padding: 10px 10px;
    h2 {
      font-size: 29px;
    }
    .buttons-container {
      flex-direction: row;
      align-items: center;
    }
    button {
      font-size: 14px;
      padding: 0 10px;
    }
  }
}
}
</style>