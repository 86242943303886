<template>
  <div
    class="flex flex-row flex-wrap w-full justify-center"
    :style="{
      position: 'relative',
      overflowX: 'hidden',
    }"
  >
    <!-- <div
      style="
        position: fixed;
        color: red;
        top: 50px;
        left: 5px;
        z-index: 9999;
        background: black;
      "
    >
      <div>stage: {{ stage }}</div>
      <button @click="bossAnimateAppearance">bossAnimateAppearance</button>
      <button @click="bossSay">bassSay</button>
    </div> -->
    <!-- preload all images -->
    <div v-for="(f, fkey) in images" :key="fkey">
      <div
        :style="{
          position: 'absolute',
          backgroundImage: `url(/images/${f}.png)`,
        }"
      />
    </div>

    <!-- SLOT window -->
    <div
      class="flex flex-row flex-wrap w-full justify-center absolute top-0"
      style="z-index: 10000"
    >
      <GameHeader />
    </div>
    <!-- get conf -->
    <!-- v-if="stage === 'idle_phone' && !gameInfo.current_level" -->
    <!-- get music InfoMusic -->
    <transition-fade>
      <InfoMusic
        v-if="stage === 'idle_music'"
        :style="{
          position: 'absolute',
          zIndex: 10000,
        }"
        @finished="infoTopFinished"
      />
    </transition-fade>
    <!-- Boss fight ended dialog -->
    <DialogBossEnd
      v-if="bossStats"
      :style="{
        position: 'absolute',
        zIndex: 1000,
        top: '0px',
        width: '100%',
        height: '100%',
      }"
      @finished="(bossStats = false), (stage = 'idle_npc'), sberOffer()"
      @gotofinal="nextLevel"
    />
    <!-- ground -->
    <div
      class="flex flex-row flex-wrap w-full justify-center relative"
      :style="{
        height: $store.state.ui.width > 768 ? '100vh' : '35vh',
      }"
    >
      <Sky />
      <div
        ref="tint"
        v-if="
          ['c_1', 'c_2', 'c_3'].includes(gameInfo.current_level) &&
          $store.state.ui.width > 768
        "
        class="blur"
      />
      <!-- SLOT city -->
      <div
        class="flex flex-row flex-wrap w-full justify-center relative h-full"
        :style="{
          maxWidth: 1480 + 'px',
        }"
      >
        <!-- SLOT view 1280 -->

        <Hero
          v-if="stage !== 'idle_music'"
          ref="hero"
          :style="{
            position: 'absolute',
            zIndex: 1001,
            bottom: '20px',
            left: '0px',
            pointerEvents: 'none !important',
          }"
        />
        <Boss
          v-if="
            ['c_1', 'c_2', 'c_3'].includes(gameInfo.current_level) && !bossStats
          "
          ref="boss"
          class="absolute right-0 bottom-5"
          :boss-id="gameInfo.current_level"
          :style="{
            zIndex: 201,
            pointerEvents: 'none !important',
          }"
        />
        <Npc
          ref="npc"
          :style="{
            pointerEvents: 'none !important',
          }"
        />
        <!-- body -->
        <div
          class="flex flex-row flex-wrap w-full relative"
          :style="{
            zIndex: 101,
            maxWidth: 700 + 'px',
            backgrounds: 'hsla(120,20%,50%,0.5)',
            marginTop: $store.state.ui.width > 768 ? '10vh' : '35vh',
            maxHeight: $store.state.ui.width > 768 ? '300px' : '65vh',
            padding: $store.state.ui.width > 768 ? '0px' : '10px',
            marginBottom: '300px',
            overflow: $store.state.ui.width > 768 ? 'visible' : 'auto',
            paddingTop: '30px',
          }"
        >
          <!-- SLOT dialog -->
          <!-- offer -->
          <DialogOffer
            v-if="offer"
            :style="{
              maxWidth: $store.state.ui.width > 768 ? '600px' : 'max-content',
            }"
            @finished="nextLevel(), (offer = false)"
          />
          <!-- game start -->
          <DialogFlow
            v-else-if="stage === 'idle_npc'"
            ref="dialog"
            :dialogs="levelMap.messagesIntro"
            :style="{
              maxWidth: $store.state.ui.width > 768 ? '600px' : '70vh',
            }"
            @finished="fightBoss"
          />
          <!-- end dialog -->
          <DialogFlow
            v-if="stage === 'afterLastBoss'"
            ref="dialog"
            :dialogs="levelMap.messageOnWinAfter"
            :style="{
              maxWidth: $store.state.ui.width > 768 ? '600px' : '70vh',
            }"
            @finished="nextLevel"
          />
          <!-- Boss -->
          <QuizBossFlow
            v-if="
              ['c_1', 'c_2', 'c_3'].includes(gameInfo.current_level) &&
              stage === 'fight'
            "
            :refsParent="$refs"
            @finished="quizBossFlowFinished"
          />
          <!-- Offer -->
          <!-- Final -->
        </div>
      </div>
    </div>
    <!-- underground -->
    <div
      v-if="$store.state.ui.width <= 768"
      class="flex flex-row flex-wrap w-full"
      :style="{
        position: 'relative',
        zIndex: 1000,
        minHeight: '65vh',
        background: '19192F',
        pointerEvents: 'none',
        marginBottoms: '0px',
        overflow: 'auto',
      }"
    >
      <!-- SLOT underground -->
    </div>
  </div>
</template>

<script>
import GameHeader from '@/components/Game/GameHeader.vue';
import Sky from '@/components/Game/Sky.vue';
import Hero from '@/components/Game/Hero.vue';
import Boss from '@/components/Game/Boss.vue';
import Npc from '@/components/Game/Npc.vue';
import DialogFlow from '@/components/Game/DialogFlow.vue';
import DialogOffer from '@/components/Game/DialogOffer.vue';
import DialogBossEnd from '@/components/Game/DialogBossEnd.vue';
import QuizBossFlow from '@/components/Game/QuizBossFlow.vue';

import InfoMusic from '@/components/Game/InfoMusic.vue';
import TransitionFade from '../components/UI/TransitionFade.vue';

export default {
  name: 'GameLayout',
  components: {
    GameHeader,
    Sky,
    Hero,
    Boss,
    Npc,
    DialogFlow,
    DialogOffer,
    DialogBossEnd,
    QuizBossFlow,
    InfoMusic,
    TransitionFade,
  },
  data() {
    return {
      stage: 'idle_music', // fight, choose_spec
      images: [
        'sky-first',
        'sky-second',
        'sky-third',
        'city-first',
        'city-second',
        'city-third',
        'city-first-bg',
        'city-second-bg',
        'city-third-bg',
      ],
      bossStats: false,
      offer: false,
    };
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    couldOffer() {
      return (
        this.gameInfo.result.level.c_1.correct +
          this.gameInfo.result.level.c_2.correct >=
        7
      );
    },
    levelMap() {
      return this.$store.state.game.gameInfoMap.levels[
        this.gameInfo.current_level
      ];
    },
    allQuestions() {
      return (
        this.gameInfo.result.level[this.gameInfo.current_level]?.correct +
          this.gameInfo.result.level[this.gameInfo.current_level]?.incorrect ===
        5
      );
    },
    user() {
      return this.$store.getters['user/user'];
    },
  },
  watch: {
    '$store.state.game.gameInfo.current_level': {
      // deep: true,
      // immediate: true,
      async handler(to, from) {
        // Control final
        if (to === 'final') {
          // do stuff
          this.$router.replace('/final').catch((e) => e);
        }
        this.$log('gameInfo.current_level CHANGED', to, from);
        // Control animations
        this.$nextTick(() => {
          this.tintDessappear();
        });

        // Control idle stage
        if (to === 'idle') {
          this.stage = 'idle_npc';
        }

        // Control boss stages
        if (['c_1', 'c_2', 'c_3'].includes(to)) {
          // Make stage idle to show intro dialogs, only when hitpoints === 3, for player and boss
          if (!this.allQuestions) {
            if (from === 'idle') {
              this.stage = 'fight';
            } else {
              this.stage = 'idle_npc';
            }
          }
          // They are damaged somehow
          else {
            this.nextLevel();
          }
          this.bossAnimateAppearance();
        }
      },
    },
  },
  methods: {
    bossSay() {
      this.$nextTick(async () => {
        if (this.$refs.boss) {
          this.$refs.boss.say(this.levelMap.messages[0].message, 6000);
          await this.$wait(5000);
        }
      });
    },
    bossAnimateAppearance() {
      this.$nextTick(async () => {
        // boss appearance
        await this.$wait(100);
        if (this.$refs.boss) {
          this.$refs.boss.animAppearance();
        }
      });
    },
    tintDessappear() {
      this.$log('tintDessappear');
      this.$tween.to(this.$refs.tint, 1, { opacity: '0' });
    },
    tintApear() {
      this.$log('tintApear');
      this.$tween.to(this.$refs.tint, 1, { opacity: '1' });
    },
    quizBossFlowFinished() {
      this.$log('quizBossFlowFinished');
      this.bossStats = true;
    },
    sberOffer() {
      this.$log('sberOffer');
      // this.$refs.hero.animRessurrect();
      // If the last boss?
      if (this.gameInfo.current_level === 'c_2' && this.couldOffer) {
        // And you killed him
        this.offer = true;
      }
      // No? Go next level
      else {
        this.nextLevel();
      }
    },
    async nextLevel() {
      this.$log('nextLevel', this.gameInfo.current_level);
      const levelCurrent = this.gameInfo.current_level;
      // Get next level
      const {
        levels: {
          [levelCurrent]: { levelNext },
        },
      } = this.$store.state.game.gameInfoMap;
      this.$log('levelNext', levelNext);

      // change level
      const {
        data: levelChanged,
      } = await this.$http.post('/api/game/change-level', { level: levelNext });
      this.$log('levelChanged', levelChanged);
      // Update gameState
      await this.$store.dispatch('game/fetchGame');
      // Handle final stage
      if (this.gameInfo.current_level === 'final') {
        this.$router.replace('/final').catch(() => {});
      }
    },
    async gameStart() {
      this.$log('gameStart start');
      // animations
      this.stage = 'fight';

      this.$refs.hero.animIdle();
      await this.$store.dispatch('game/startGame');
      await this.$store.dispatch('game/fetchGame');
      this.$log('gameStart done');
    },
    async fightBoss() {
      this.$log('fightBoss');
      // First level just go next
      if (this.gameInfo.current_level === 'idle') {
        this.nextLevel();
      }
      // Fight in boss level
      else {
        this.stage = 'fight';
        // then
        this.tintApear();
        this.bossSay();
      }
    },
    async infoTopFinished() {
      if (!this.gameInfo.id) {
        await this.$store.dispatch('game/startGame');
      }
      await this.$store.dispatch('game/fetchGame');
      await this.$nextTick();
      this.stage = 'idle_npc';
      this.bossAnimateAppearance();
      if (this.allQuestions) {
          this.nextLevel();
        }
      // this.$refs.hero.animRun();
    },
  },
  async mounted() {
    this.stage === 'idle_music';
    // this.gameStart()
  },
  beforeDestroy() {
    this.$log('beforeDestroy');
  },
};
</script>

<style lang="scss">
.blur {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    -90deg,
    rgba(35, 1, 42, 0) 0%,
    #070415 100%
  );
  z-index: 10;
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  body {
    background: #191930;
  }
}
</style>
