var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row flex-wrap w-full justify-center",style:({
    position: 'relative',
    overflowX: 'hidden',
  })},[_vm._l((_vm.images),function(f,fkey){return _c('div',{key:fkey},[_c('div',{style:({
        position: 'absolute',
        backgroundImage: ("url(/images/" + f + ".png)"),
      })})])}),_c('div',{staticClass:"flex flex-row flex-wrap w-full justify-center absolute top-0",staticStyle:{"z-index":"10000"}},[_c('GameHeader')],1),_c('transition-fade',[(_vm.stage === 'idle_music')?_c('InfoMusic',{style:({
        position: 'absolute',
        zIndex: 10000,
      }),on:{"finished":_vm.infoTopFinished}}):_vm._e()],1),(_vm.bossStats)?_c('DialogBossEnd',{style:({
      position: 'absolute',
      zIndex: 1000,
      top: '0px',
      width: '100%',
      height: '100%',
    }),on:{"finished":function($event){(_vm.bossStats = false), (_vm.stage = 'idle_npc'), _vm.sberOffer()},"gotofinal":_vm.nextLevel}}):_vm._e(),_c('div',{staticClass:"flex flex-row flex-wrap w-full justify-center relative",style:({
      height: _vm.$store.state.ui.width > 768 ? '100vh' : '35vh',
    })},[_c('Sky'),(
        ['c_1', 'c_2', 'c_3'].includes(_vm.gameInfo.current_level) &&
        _vm.$store.state.ui.width > 768
      )?_c('div',{ref:"tint",staticClass:"blur"}):_vm._e(),_c('div',{staticClass:"flex flex-row flex-wrap w-full justify-center relative h-full",style:({
        maxWidth: 1480 + 'px',
      })},[(_vm.stage !== 'idle_music')?_c('Hero',{ref:"hero",style:({
          position: 'absolute',
          zIndex: 1001,
          bottom: '20px',
          left: '0px',
          pointerEvents: 'none !important',
        })}):_vm._e(),(
          ['c_1', 'c_2', 'c_3'].includes(_vm.gameInfo.current_level) && !_vm.bossStats
        )?_c('Boss',{ref:"boss",staticClass:"absolute right-0 bottom-5",style:({
          zIndex: 201,
          pointerEvents: 'none !important',
        }),attrs:{"boss-id":_vm.gameInfo.current_level}}):_vm._e(),_c('Npc',{ref:"npc",style:({
          pointerEvents: 'none !important',
        })}),_c('div',{staticClass:"flex flex-row flex-wrap w-full relative",style:({
          zIndex: 101,
          maxWidth: 700 + 'px',
          backgrounds: 'hsla(120,20%,50%,0.5)',
          marginTop: _vm.$store.state.ui.width > 768 ? '10vh' : '35vh',
          maxHeight: _vm.$store.state.ui.width > 768 ? '300px' : '65vh',
          padding: _vm.$store.state.ui.width > 768 ? '0px' : '10px',
          marginBottom: '300px',
          overflow: _vm.$store.state.ui.width > 768 ? 'visible' : 'auto',
          paddingTop: '30px',
        })},[(_vm.offer)?_c('DialogOffer',{style:({
            maxWidth: _vm.$store.state.ui.width > 768 ? '600px' : 'max-content',
          }),on:{"finished":function($event){_vm.nextLevel(), (_vm.offer = false)}}}):(_vm.stage === 'idle_npc')?_c('DialogFlow',{ref:"dialog",style:({
            maxWidth: _vm.$store.state.ui.width > 768 ? '600px' : '70vh',
          }),attrs:{"dialogs":_vm.levelMap.messagesIntro},on:{"finished":_vm.fightBoss}}):_vm._e(),(_vm.stage === 'afterLastBoss')?_c('DialogFlow',{ref:"dialog",style:({
            maxWidth: _vm.$store.state.ui.width > 768 ? '600px' : '70vh',
          }),attrs:{"dialogs":_vm.levelMap.messageOnWinAfter},on:{"finished":_vm.nextLevel}}):_vm._e(),(
            ['c_1', 'c_2', 'c_3'].includes(_vm.gameInfo.current_level) &&
            _vm.stage === 'fight'
          )?_c('QuizBossFlow',{attrs:{"refsParent":_vm.$refs},on:{"finished":_vm.quizBossFlowFinished}}):_vm._e()],1)],1)],1),(_vm.$store.state.ui.width <= 768)?_c('div',{staticClass:"flex flex-row flex-wrap w-full",style:({
      position: 'relative',
      zIndex: 1000,
      minHeight: '65vh',
      background: '19192F',
      pointerEvents: 'none',
      marginBottoms: '0px',
      overflow: 'auto',
    })}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }