<template>
  <div
    class="flex flex-row flex-wrap w-full"
  >
    <!-- v-if="question && userSpec && userSpec === 'non-code' && stage === 'fight'" -->
    <QuizBoss
      v-if="question && stage === 'fight'"
      ref="quiz"
      :question="question.data"
      :description="description"
      :isRight="isRight"
      :isAnswerLoading="answerQuestionWorking"
      :isNextQuestionLoading="isNextQuestionLoading"
      @answer="answerQuestion"
      @next="nextQuestion"
    />
  </div>
</template>

<script>
import QuizBoss from '@/components/Game/QuizBoss.vue'

export default {
  name: 'QuizBossFlow',
  props: ['refsParent'],
  components: {
    QuizBoss,
  },
  data () {
    return {
      stage: 'idle', // idle, fight, win, lose, final, offer...
      question: null,
      answerQuestionWorking: false,
      isNextQuestionLoading: false,
      description: null,
      isRight: null,
    }
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    levelMap() {
      return this.$store.state.game.gameInfoMap.levels[this.gameInfo.current_level];
    },
    userSpec() {
      return this.$store.getters.user.specialization_id;
    },
    corrects() {
      return this.gameInfo.result.level[this.gameInfo.current_level].correct
    },
    incorrects() {
      return this.gameInfo.result.level[this.gameInfo.current_level].incorrect
    },
    allQuestions() {
      return this.corrects + this.incorrects === 5 ? true : false
    }
  },
  watch: {
    gameInfo: {
      immediate: true,
      handler (to) {
        // Control the middle of the fight
        if (to && (!this.allQuestions)) {
          this.stage = 'fight'
        }
      }
    }
  },
  methods: {
    async tryEaster () {
      this.$log('tryEaster start')
      // this.refsParent.npc.animAppear('ПАСХАЛКА', 3000, 'positive')
      if (this.question && this.question.data.easter) {
        // this.refsParent.npc.animAppear(this.question.data.easter, 3000, 'positive');
        // await this.$wait(3000)
        this.$log('tryEaster done')
      }
    },
    async answerQuestion(id) {
      this.$log('answerQuestion', id);
      if (this.answerQuestionWorking) return;
      this.answerQuestionWorking = true;
      // get result
      const { data } = await this.$http.post('/api/game/answer-boss-question', {
        level: this.gameInfo.current_level,
        question: this.question.data.id,
        answer: id,
      });
      this.$log('handleSendAnswer BOSS', data);
      // Update quiz info
      // this.$set(this, 'question', { ...data, answers: data.result.answers });
      // this.$refs.quiz.result = data.result;
      // Get answer and answerIsCorrect
      // const answer = data.result.answers.find((a) => a.id === id);
      // const answerIsCorrect = answer.is_user_right;
      const answerIsCorrect = data;
      // do stuff
      let i = ''
      if (answerIsCorrect) {
        // Animations
        // this.refsParent.hero.animAttack();
        // await this.$wait(500);
        // this.refsParent.boss.animDamaged();
        // On first damage TO boss, Before Damage to Boss his health is 3
        i = this.question.data.correct_text
        this.isRight = true
        if (i === null) i = 'Верно!'
        if (this.incorrects === 1) {
          this.refsParent.boss.say(this.levelMap.messagesOnFirstHit[0].message, 4000);
          // await this.$wait(2000)
          // this.refsParent.npc.animAppear(this.levelMap.messagesOnFirstHit[1].message, 3000, 'positive');
          // await this.$wait(2000)
          await this.tryEaster()
        }
        else {
          await this.tryEaster()
        }
      }
      else {
        // Animations
        // this.refsParent.boss.animAttack();
        // await this.$wait(1000);
        // this.refsParent.hero.animDamaged();
        // On first damage FROM boss, befor damage from Boss, hero health is 3
        i = this.question.data.incorrect_text
        this.isRight = false
        if (i === null) i = 'Неверно!'
        if (this.corrects === 1) {
          this.refsParent.boss.say(this.levelMap.messagesOnFirstDamage[0].message, 4000);
          // await this.$wait(2000)
          // this.refsParent.npc.animAppear(this.levelMap.messagesOnFirstDamage[1].message, 3000, 'wrong');
          // await this.$wait(2000)
          await this.tryEaster()
        }
        else {
          await this.tryEaster()
        }
      }
      // Update game state, this will change hit points...
      await this.$store.dispatch('game/fetchGame')
      // Next question
      // await this.nextQuestion();
      this.description = i
      // Done
      this.answerQuestionWorking = false;
    },
    async nextQuestion() {
      this.$log('nextQuestion')
      // check win
      if (this.gameInfo.result.level[this.gameInfo.current_level].correct >= 3 && this.allQuestions) {
        this.stage = 'win'
        this.refsParent.boss.say(this.levelMap.messagesOnWin[0].message, 4000)
        await this.$wait(4000)
        // this.refsParent.npc.animAppear(this.levelMap.messagesOnWin[1].message, 3000, 'emotion')
        this.refsParent.boss.animDeath()
        await this.$wait(2000)
        this.$emit('finished')
      }
      // check lose
      else if (this.gameInfo.result.level[this.gameInfo.current_level].correct < 3 && this.allQuestions) {
        this.stage = 'lose'
        this.refsParent.boss.say(this.levelMap.messagesOnLose[0].message, 3000)
        await this.$wait(3000)
        // this.refsParent.npc.animAppear(this.levelMap.messagesOnLose[1].message, 3000, 'emotion')
        this.refsParent.boss.animDeath()
        await this.$wait(2000)
        this.$emit('finished')
      }
      else {
        // update quiz
        this.isNextQuestionLoading = true
        this.question = await this.getQuestion()
        if (this.$refs.quiz) this.$refs.quiz.result = null
        this.description = null
        this.isRight = null
        this.isNextQuestionLoading = false
      }
    },
    async getQuestion() {
      this.$log('getQuestion');
      const { data: question } = await this.$http.post('/api/game/new-boss-question', {
        try: this.gameInfo.try,
        level: this.gameInfo.current_level,
      });
      console.log(question, 'Question')
      return question;
    },
  },
  async mounted () {
    this.$log('mounted')
    this.$nextTick(async () => {
      this.stage = 'fight'
      this.question = await this.getQuestion()
    })
  },
  beforeDestroy () {
    this.$log('beforeDestroy')
  }
}
</script>
