var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"dialog",staticClass:"quiz-boss"},[_c('div',{staticClass:"quiz-header"},[_vm._v(" "+_vm._s(_vm.currentQuestion)+" вопрос из "+_vm._s(_vm.totalQuestions)+" ")]),_c('div',{ref:"content",staticClass:"quiz-content"},[_c('div',{staticClass:"flex flex-row flex-wrap w-full flex-shrink-0",style:({
        position: 'relative',
        paddingBottom: '0px',
      })},[(_vm.question.image && _vm.$store.state.ui.width >= 1200)?_c('div',{style:({
          position: 'absolute',
          zIndex: 400,
          top: '0px',
          right: '-20px',
          overflow: 'visible',
          width: '0px',
        })}):_vm._e(),_c('p',{staticClass:"question-text"},[_c('vue-markdown',{attrs:{"source":_vm.question.question},on:{"rendered":_vm.update}})],1),(_vm.question.image)?_c('img',{staticClass:"boss-image",style:({
          zIndex: 10,
          height: _vm.$store.state.ui.width > 768 ? '300px' : '200px',
          maxWidth: '100%',
          pointerEvents: 'none',
          userSelect: 'none',
          objectFit: 'contain',
          marginTop: '20px',
          marginBottom: '20px',
        }),attrs:{"draggable":"false","src":_vm.question.image}}):_vm._e()]),_c('div',{staticClass:"answers-block-title"},[_vm._v("Выбери верный вариант ответа")]),_c('div',{staticClass:"w-full"},_vm._l((_vm.question.answers),function(answer){return _c('button',{key:answer.id,staticClass:"ans-btn quiz__answer",class:{
          answered: _vm.answerSelectedId === answer.id,
          wrong: _vm.answerSelectedId === answer.id && _vm.isRight === false,
        },staticStyle:{"max-width":"500px","width":"100%"},style:({
          height: 'auto !important',
        }),attrs:{"disabled":_vm.description},on:{"click":function($event){_vm.answerSelectedId = answer.id}}},[_vm._v(" "+_vm._s(answer.text)+" ")])}),0),(_vm.description)?_c('div',{staticClass:"description"},[_c('p',[_vm._v(" "+_vm._s(_vm.description)+" ")])]):_vm._e(),_c('div',{staticClass:"flex flex-row flex-wrap w-full",style:({
        paddingTop: '20px',
        minHeight: '60px',
      })},[(true)?_c('button',{staticClass:"geecko-button",class:{'geecko-button--loading': _vm.isAnswerLoading || _vm.isNextQuestionLoading},style:({
          background: !_vm.answerSent ? '#237a3b' : '#237a3b',
        }),attrs:{"disabled":_vm.isAnswerLoading || _vm.isNextQuestionLoading},on:{"click":function($event){(_vm.answerSent = true), _vm.answer()}}},[_vm._v(" "+_vm._s(_vm.description ? 'Далее' : 'Ответить')+" ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }