var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-container"},[_c('div',{staticClass:"info-container-block"},[_c('h2',[_vm._v(" Как будешь играть? ")]),_c('div',{staticClass:"buttons-container"},[_c('button',{staticClass:"geecko-button justify-center items-center",style:({
          background: '#FFEF40',
          display: 'flex',
          flexDirection: 'flex-row',
          maxWidth: '180px',
          width: '300px',
          border: '1px solid red;',
          padding: 0
        }),attrs:{"type":"submit"},on:{"click":function($event){return _vm.handleSubmit('music-on')}}},[_c('div',{staticClass:"music music-on"}),_vm._v(" C музыкой ")]),_c('button',{staticClass:"geecko-button--outline justify-center items-center",style:({
          display: 'flex',
          flexDirection: 'flex-row',
          maxWidth: '180px',
          width: '300px',
          border: '1px solid red;',
          padding: 0
        }),attrs:{"type":"submit"},on:{"click":function($event){return _vm.handleSubmit('music-off')}}},[_c('div',{staticClass:"music music-off"}),_vm._v(" Без музыки ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }