<template>
  <div
    ref="dialog"
    class="dialog"
    :class="[corrects >= 3 ? 'win-color' : 'defeat-color']"
  >
    <div class="dialog-flex-container">
      <!-- <p> Правильных ответов {{ correct + '/' + all }} </p> -->
    </div>
    <div class="dialog-flex-container">
      <h3 v-if="corrects < 3">Оффер не увеличился</h3>
      <h2 v-else>Оффер увеличился</h2>
    </div>
    <!-- header -->
    <div class="dialog-flex-container flex flex-col">
      <p>
        Правильных ответов:
        <span
          style="font-weight: 400; color: #fff; font-family: 'BlenderPro'"
          >{{ gameInfo.result.level[gameInfo.current_level].correct }}</span
        >
      </p>
      <p>
        Неправильных ответов:
        <span
          style="font-weight: 400; color: #fff; font-family: 'BlenderPro'"
          >{{ gameInfo.result.level[gameInfo.current_level].incorrect }}</span
        >
      </p>
    </div>
    <!-- body -->
    <div class="dialog-flex-container">
      <p v-if="corrects >= 3">Отлично! Ты показал ему свой скилл!</p>
      <p v-else>Главное это не твои ошибки, а твоя реакция на них</p>
    </div>
    <!-- action -->
    <div class="dialog-flex-container mt-24">
      <div class="dialog-flex-container">
        <button
          v-if="gameInfo.current_level !== 'c_3'"
          class="geecko-button"
          @click="$emit('finished')"
        >
          К следующей главе
        </button>
        <button v-else class="geecko-button" @click="$emit('gotofinal')">
          К результатам
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import StarRating from 'vue-star-rating';

export default {
  name: 'DialogBossEnd',
  // components: { StarRating },
  props: {},
  data() {
    return {};
  },
  computed: {
    playerName() {
      return this.$store.getters.user.data.username;
    },
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    corrects() {
      return this.gameInfo.result.level[this.gameInfo.current_level]?.correct;
    },
    incorrects() {
      return this.gameInfo.result.level[this.gameInfo.current_level]?.incorrect;
    },
    starSize() {
      let size;
      if (window.innerWidth < 768) {
        size = 60;
      } else {
        size = 90;
      }
      return size;
    },
    level() {
      let level;
      switch (this.gameInfo.current_level) {
        case 'c_1':
          level = 0;
          break;
        case 'c_2':
          level = 8;
          break;
        case 'c_3':
          level = 16;
          break;
        default:
          break;
      }
      return level;
    },
  },
  mounted() {
    this.$log('moutned');
    this.$nextTick(async () => {
      const dialog = this.$refs.dialog;
      this.$tween.set(dialog, { opacity: '0' });
      this.$tween.to(dialog, 1, { opacity: '1' });
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.geecko-button {
  width: 230px;
}
p {
  color: #e0e0e0;
  max-width: 500px;
  text-align: center;
}
.dialog {
  position: absolute;
  color: #e0e0e0;
  // width: 100%;
  // height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  &-flex-container {
    display: flex;
    // font-family: 'BlenderPro';
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding-top: 20px;
    h1 {
      font-size: 46px;
    }
    h2 {
      font-family: 'Sans Ui';
      font-style: normal;
      font-weight: bold;
      font-size: 60px;
      line-height: 120%;
      /* identical to box height, or 72px */

      text-align: center;
      text-transform: uppercase;

      color: #b9ffb3;
    }
    h3 {
      font-family: 'Sans Display';
      font-style: normal;
      font-weight: 1000;
      font-size: 65px;
      line-height: 120%;
      /* identical to box height, or 72px */

      text-align: center;
      text-transform: uppercase;

      color: #ffc7c7;
    }
  }
}
.defeat-color {
  background-image: linear-gradient(
    -90deg,
    rgba(108, 8, 8, 0.3) 30%,
    rgba(108, 8, 8, 0.7) 150%
  );
}
.win-color {
  background-image: linear-gradient(
    -90deg,
    rgba(35, 1, 60, 0.3) 30%,
    rgba(19, 110, 61, 0.7) 150%
  );
}
@media screen and (max-width: 768px) {
  .dialog {
    position: absolute;
    color: #e0e0e0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    &-flex-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 0px;
      padding-bottom: 0px;
      :nth-child(1) {
        margin-top: 10px;
      }
      h2 {
        font-family: 'Sans Ui';
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 120%;
        /* identical to box height, or 72px */

        text-align: center;
        text-transform: uppercase;

        color: #b9ffb3;
      }
      h3 {
        font-family: 'Sans Display';
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 120%;
        /* identical to box height, or 72px */

        text-align: center;
        text-transform: uppercase;

        color: #ffc7c7;
      }
      h1 {
        font-size: 34px;
        margin: 0;
        padding: 0;
      }
      .geecko-button {
        width: 180px;
        text-align: center;
        margin: 0;
        padding: 0;
      }
    }
  }
  .defeat-color {
    background-image: linear-gradient(
      -90deg,
      rgba(35, 1, 42, 0) -40%,
      #6c0808 150%
    );
  }
  .win-color {
    background-image: linear-gradient(
      -90deg,
      rgba(35, 1, 42, 0) -100%,
      #136e3d 130%
    );
  }
}
</style>
