<template>
  <div
    ref="dialog"
    class="dialog"
    :style="{
    }">
    <!-- header -->
    <div class="dialog-head ">
      <div
        class="dialog-head__img "
        :style="{ backgroundImage: `url('/images/helper.png')` }"
      />
      <div class="dialog-head__name">
        Рекрутер
      </div>
    </div>
    <!-- body -->
    <div ref="cont" class="dialog-content">
      <slot />
      <!-- footer -->
      <div
        class="flex flex-row flex-wrap w-full"
      >
        <button class="geecko-button" @click="handleClick">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    headerText: { type: String, default: 'Старейшина' },
    headerImage: { type: String, default: '/images/teamleadus.png' },
    buttonText: { type: String, default: 'Далее' },
    handleClick: { type: Function, default: () => {} },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  async mounted () {
    this.$nextTick(async () => {
      this.$log('mounted')
      const dia = this.$refs.dialog;
      const anim = new this.$timeline();
      const cont = this.$refs.cont;
      anim.set(dia, { width: '0%', height: '48px' }).set(cont, { height: '0%', padding: '0 32px', overflow: 'hidden' });
      // anim.set(cont, { opacity: '0' });
      anim.to(dia, 0.4, { width: '55vw' });
      // anim.to(cont, 1, { opacity: '1' });
      await this.$wait(100);
      anim.to(dia, 0.2, { height: 'auto' }).to(cont, 0.2, { height: 'auto', padding: '24px 32px' });
    })
  },
  methods: {
    dissapear() {
      const dia = this.$refs.dialog;
      this.$tween.to(dia, 1, { opacity: '0' });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  width: 100%;
  min-width: 100%;
  height: auto;
  // border: 1px solid red;
  &-head {
    color: #e0e0e0;
    background: #2e2c3f;
    position: relative;
    background: #111316;
    padding-left: 95px;
    --height: 45px;
    border-radius: 8px 8px 0px 0px;
    height: var(--height);
    // --clip-size: 16px;
    // clip-path: polygon(
    //   0 0,
    //   0 -29px,
    //   calc(100% - var(--clip-size)) 0,
    //   100% var(--clip-size),
    //   100% 100%,
    //   0 100%
    // );
    &__img {
      position: absolute;
      left: 0px;
      bottom: 0;
      --size: 100px;
      height: 90px;
      width: var(--size);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    &__name {
      font-size: 17px;
      line-height: 100%;
      font-family: 'BlenderPro Bold';
      line-height: var(--height);
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  &-content {
    background: #191B20;
    padding: 24px 32px 32px 32px;
    border-radius: 0px 0px 8px 8px;
    & .geecko-button {
      margin-top: 20px;
    }
  }
}

.dialog-text {
  color: #e0e0e0;
  display: inline-block;
}
@media screen and (max-width: 768px) {
.dialog {
  width: 100%;
  min-width: 100%;
  height: auto;
  &-head {
    &__img {
      height: 60px;
      --size: 60px;
    }
  }
  &-content {
    background: #201e2e;
    padding: 10px !important;
    overflow: auto !important;
    margin: 0px;
    // max-height: calc(90vh - 20px);
    // border: 1px solid red;
    & .geecko-button {
      margin-top: 20px;
    }
  }
}
}
</style>
