<template>
  <!-- hero wrapper -->
  <div
    class="player-container"
    :style="{
      height: height+'px',
      width: width+'px',
    }"
  >
    <!-- download images -->
    <div v-for="im in images" :key="im.id">
      <div :style="{position: 'absolute', zIndex: '-1', backgroundImage: `url(/images/game/player/${im}.png)`}" />
    </div>
    <!-- health only on boss levels -->
    <div
      ref="bar"
      v-if="['c_1', 'c_2', 'c_3'].includes(gameInfo.current_level)"
      class="health-container ">
      <!-- <div
        v-for="h in incorrects" :key="h.id" style="font-size: 40px;">
        ❌
        <div
          :ref="`h-${h}`"
          class="health-bar-value"
          :style="{
            height: '30px',
          }"
        />
      </div> -->
    </div>
    <!-- hero -->
    <div
      ref="hero"
      class="player"
      :style="{
        width: width + 'px',
        height: height + 'px'
      }"
    />
  </div>
</template>

<script>
import { SteppedEase } from 'gsap';
// import { Power0 } from 'gsap';

export default {
  name: 'Hero',
  data() {
    return {
      timeline: null,
      animationInProcess: false,
      idle: null,
      run: null,
      el: null,
      attack: null,
      pain: null,
      hp: null,
      images: [
        'idle',
        'run',
        'death',
        'ressurrect',
        'damaged',
        'attack',
        'death-end'
      ],
      animImg: {
        idle: 24, death: 29, ress: 29, run: 38, dmg: 14, atk: 29
      }
    };
  },
  computed: {
    gameInfo() {
      return this.$store.state.game.gameInfo;
    },
    levelMap() {
      return this.$store.state.game.gameInfoMap.levels[this.gameInfo.current_level];
    },
    height () {
      return this.$store.state.ui.width > 768 ? 350 : 130
    },
    width () {
      return this.$store.state.ui.width > 768 ? 280 : 100
    },
    incorrects() {
      return this.gameInfo.result.level[this.gameInfo.current_level].incorrect
    },
  },
  watch: {
    // health: {
    //   immediate: true,
    //   handler(to, from) {
    //     this.$log('health TO', to, from)
    //     if (to < 3 && from) {
    //       this.$nextTick(() => {
    //         const hp = this.$refs['h-'+from][0]
    //         this.$log(to, from, hp, 'HEALTH')
    //         this.$tween.to(hp, 2, { maxWidth: '0%' })
    //       })
    //     }
    //     else {
    //       if (!['c_1', 'c_2', 'c_3'].includes(this.gameInfo.current_level)) return
    //       this.$nextTick(() => {
    //         this.$tween.set(this.$refs['h-1'][0], { maxWidth: '100%' })
    //         this.$tween.set(this.$refs['h-2'][0], { maxWidth: '100%' })
    //         this.$tween.set(this.$refs['h-3'][0], { maxWidth: '100%' })
    //       })
    //     }
    //   }
    // },
  },
  async mounted () {
    this.$log('mounted')
    this.$nextTick(async () => {
      this.el = this.$refs.hero
      this.$tween.set(this.$refs.bar, { opacity: 0 })
      this.animIdle()
      if (!['c_1', 'c_2', 'c_3'].includes(this.gameInfo.current_level)) return
      // if (this.health === 0) {
      //   this.$tween.set(this.$refs['h-1'][0], { maxWidth: '0' })
      //   this.$tween.set(this.$refs['h-2'][0], { maxWidth: '0' })
      //   this.$tween.set(this.$refs['h-3'][0], { maxWidth: '0' })
      // }
      // if (this.health === 1) {
      //   this.$tween.set(this.$refs['h-1'][0], { maxWidth: '100%' })
      //   this.$tween.set(this.$refs['h-2'][0], { maxWidth: '0' })
      //   this.$tween.set(this.$refs['h-3'][0], { maxWidth: '0' })
      // }
      // if (this.health === 2) {
      //   this.$tween.set(this.$refs['h-1'][0], { maxWidth: '100%' })
      //   this.$tween.set(this.$refs['h-2'][0], { maxWidth: '100%' })
      //   this.$tween.set(this.$refs['h-3'][0], { maxWidth: '0' })
      // }
      // if (this.health === 3) {
      //   this.$tween.set(this.$refs['h-1'][0], { maxWidth: '100%' })
      //   this.$tween.set(this.$refs['h-2'][0], { maxWidth: '100%' })
      //   this.$tween.set(this.$refs['h-3'][0], { maxWidth: '100%' })
      // }
      // anim bar
      // const bar = this.$refs.bar;
      // this.$tween.set(bar, { width: '0', opacity: 0 });
      // await this.$wait(2000);
      // this.$tween.to(bar, 1, { width: '150px', opacity: 1 });
    })
  },
  methods: {
    soundAttack() {
      this.$sound('/images/game/audio/hero-attack.mp3')
    },
    soundDamaged() {
      this.$sound('/images/game/audio/hero-pain.mp3')
    },
    animIdle () {
      this.$log('animIdle')
      if (this.timeline) this.timeline.kill();
      this.timeline = new this.$timeline();
      this.timeline.set(this.el, { backgroundImage: 'url(/images/game/player/idle.png)', backgroundSize: `${this.width * this.animImg.idle}px`, backgroundPosition: 'left bottom' });
      this.timeline.to(this.el, 1, { repeat: -1, backgroundPositionX: `-${this.width * this.animImg.idle}px`, ease: SteppedEase.config(this.animImg.idle) });
    },
    animBarDiss() {
      this.$tween.set(this.$refs.bar, { opacity: 1 })
      this.$tween.to(this.$refs.bar, 1, { opacity: 0 })
    },
    animBarApp() {
      this.$tween.set(this.$refs.bar, { opacity: 0 })
      this.$tween.to(this.$refs.bar, 1, { opacity: 1 })
    },
    async animRun () {
      this.$log('animRun')
      if (this.timeline) this.timeline.kill();
      this.timeline = new this.$timeline({ repeat: 0 });
      this.timeline.set(this.el, {
        backgroundImage: `url(/images/game/player/run.png`,
        backgroundSize: `${this.animImg.run * this.width}px`,
        backgroundPosition: 'left bottom',
      });
      this.timeline.to(this.el, 1, { repeat: 0, backgroundPositionX: `-${this.animImg.run * this.width}px`, ease: SteppedEase.config(this.animImg.run) });
      this.$tween.from(this.el, 1, { x: -2000 })
      await this.$wait(900);
      this.timeline.kill();
      this.animIdle();
    },
    async animRunIn () {
      this.$log('animRunIn')
      this.timeline.kill();
      this.animRun()
      this.$tween.set(this.el, 1, { x: -500 })
      this.$tween.to(this.el, 1, { opacity: 1 })
      this.$tween.to(this.el, 3, { x: 0 })
      await this.$wait(3000)
      this.animIdle()
    },
    async animRunOut () {
      this.$log('animRunOut')
      if (this.timeline) this.timeline.kill();
      await this.$wait(500);
      this.animBarDiss()
      this.animRun()
      const running = new this.$timeline()
      running.to(this.el, 2, { x: 1500 });
      running.set(this.el, { x: -500 });
      running.to(this.el, 2, { x: 0 });
      await this.$wait(4000);
      this.animIdle();
    },
    async animDeath () {
      this.$log('animDeath')
      if (this.timeline) this.timeline.kill();
      await this.$wait(500);
      this.timeline = new this.$timeline({ repeat: 0 });
      this.timeline.set(this.el, {
        backgroundImage: 'url(/images/game/player/death.png)',
        backgroundSize: `${this.width * this.animImg.death}px`,
        backgroundPosition: 'left bottom',
        // backgroundPositionX: '9000px',
      });
      this.timeline.to(this.el, 1.5, { repeat: 0, backgroundPositionX: `-${this.width * this.animImg.death}px`, ease: SteppedEase.config(this.animImg.death) });
      await this.$wait(1400);
      this.timeline.kill();
      this.$tween.set(this.el, {
        backgroundPosition: 'left bottom',
        backgroundImage: 'url(/images/game/player/death-end.png)',
        backgroundSize: `${this.width}px`,
      })
    },
    async animRessurrect() {
      this.$log('animRessurrect')
      this.timeline.kill();
      this.timeline = new this.$timeline();
      this.timeline.set(this.el, {
        backgroundImage: 'url(/images/game/player/ressurrect.png)',
        backgroundSize: `${this.width * this.animImg.ress}px`,
        backgroundPosition: 'left bottom',
      });
      this.timeline.to(this.el, 1, { repeat: 1, backgroundPositionX: `-${this.width * this.animImg.ress}px`, ease: SteppedEase.config(this.animImg.ress) })
      await this.$wait(900)
      this.timeline.kill()
      // idle again
      this.animIdle()
    },
    async animAttack() {
      this.$log('animAttack')
      if (this.timeline) this.timeline.kill()
      this.soundAttack()
      this.timeline = new this.$timeline()
      this.timeline.set(this.el, {
        backgroundImage: 'url(/images/game/player/attack.png)',
        backgroundSize: `${this.width * this.animImg.atk}px`,
        backgroundPosition: 'left bottom',
      })
      this.timeline.to(this.el, 2, { repeat: 0, backgroundPositionX: `-${this.width * this.animImg.atk}px`, ease: SteppedEase.config(this.animImg.atk) })
      await this.$wait(2000)
      // this.attack.pause();
      // this.attack.currentTime = 0;
      this.timeline.kill()
      // back to idle
      this.animIdle()
    },
    async animDamaged() {
      this.$log('animDamaged')
      if (this.timeline) this.timeline.kill()
      this.soundDamaged()
      this.timeline = new this.$timeline()
      this.timeline.set(this.el, {
        backgroundImage: 'url(/images/game/player/damaged.png)',
        backgroundSize: `${this.width * this.animImg.dmg}px`,
        backgroundPosition: 'left bottom',
      })
      this.timeline.to(this.el, 1, { repeat: 0, backgroundPositionX: `-${this.width * this.animImg.dmg}px`, ease: SteppedEase.config(this.animImg.dmg) })
      await this.$wait(1000)
      // this.pain.pause()
      // this.pain.currentTime = 0
      this.timeline.kill()
      // back to idle
      this.animIdle()
    }
  },
};
</script>

<style lang="scss" scoped>
.health-container {
  position: absolute;
  right: 0;
  display: flex;
  top: -10%;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  left: 100px;
}
.health-bar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 70px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px;
  background: #131120;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  color: #2c2b33;
  font-family: 'BlenderPro';
  max-width: 70px;
  padding: 2px;
  margin-left: -8px;
  margin-right: -8px;
  padding-left: 6px;
  padding-right: 6px;
  top: 0;
  --border-color: #EA3344;
  border: 1px solid var(--border-color);
  --clip-size: 20px;
  &:after {
    position: absolute;
    content: '';
    border-left: var(--clip-size) solid var(--border-color);
    border-top: 20px solid transparent;
    top: -2px;
    left: 0px;
    opacity: 0.9;
  }
  &:before {
    position: absolute;
    content: '';
    border-top: var(--clip-size) solid var(--border-color);
    border-left: 20px solid transparent;
    top: 0;
    right: 0;
    opacity: 0.9;
  }
  clip-path: polygon(
    calc(100% - var(--clip-size)) 0%,
    100% 100%,
    calc(0% + var(--clip-size)) 100%,
    0% 0%);
  &-value {
    position: relative;
    display: flex;
    align-items: center;
    background: #EA3344;
    height: 100% !important;
    left: 0;
    width: 100%;
    // margin: 0px 2px;
    clip-path: polygon(
    calc(100% - 15px) 0%,
    100% 100%,
    calc(0% + 15px) 100%,
    0% 0%);
  }
}
.player-container {
  // width: 150px;
  // height: 150px;
}
.player {
  position: absolute;
  overflow: hidden;
  // background-position: left bottom;
  // background-repeat: repeat;
  // background-size: 3000px;
}
@media screen and (max-width: 1000px) {
  .health-bar {
    height: 10px;
    width: 22px;
    margin-left: -1px;
    margin-right: -1px;
    // top: 10px;
    position: relative;
    --clip-size: 8px;
  }
  .health-container {
    left: calc(50% - 20px);
    top: -7px;
  }
}
</style>
