<template>
  <div style="background: black">
    <div ref="sky" class="sky" />
  </div>
</template>

<script>
export default {
  name: 'Sky',
  computed: {},
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.sky {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
      0deg,
      rgba(9, 15, 40, 0) 72.86%,
      rgba(3, 4, 11, 0.570313) 95.9%,
      rgba(2, 3, 9, 0.85) 100%
    ),
    linear-gradient(
      180deg,
      rgba(9, 15, 40, 0) 62.86%,
      rgba(3, 4, 11, 0.570313) 100.9%,
      rgba(2, 3, 9, 0.85) 100%
    ),
    url('/images/sky.jpg');
  background-color: rgba(0, 0, 0, 0.8);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .sky {
    background-size: 700px;
  }
}
</style>
